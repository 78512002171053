import {
  HttpClient,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { Constants } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class PodService {
  API_BASE_URL = environment.API_BASE_URL;

  constructor(
    public http: HttpClient,
    private dataService: DataService,
    private commonService: CommonService
  ) {}

  public getPods(
    page?,
    perPage?,
    query?,
    status?,
    location?,
    members?,
    featureScore?,
    value?,
    min?,
    withProjects = true
  ) {
    let API = `${this.API_BASE_URL}${Constants.apiEndPoints.getPods}?with_projects=${withProjects}`;
    if (page && typeof page == 'number') {
      API += `&page=${page}`;
    }

    if (perPage && typeof perPage == 'number') {
      API += `&per_page=${perPage}`;
    }

    if (query) {
      API = `${API}&search=${query}`;
    }

    if (status && status.length > 0) {
      status.forEach((x) => {
        API += '&status[]=' + x;
      });
    }
    if (location && location.length > 0) {
      location.forEach((x) => {
        API += '&service_region[]=' + x;
      });
    }
    if (members && members.length > 0) {
      members.forEach((x) => {
        API += '&members[]=' + x;
      });
    }
    if (featureScore && featureScore.length > 0) {
      featureScore.forEach((x) => {
        API += '&feature_score[]=' + x;
      });
    }

    if (value) {
      API = `${API}&filter=status&value=${value}`;
    }

    if (min) {
      API = `${API}&min=${min}`;
    }

    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }

  public getPod(id) {
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.getPods}/${id}`;

    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }

  public getAllManagers(projectId, filters, query?) {
    let API =
      this.API_BASE_URL +
      'projects/' +
      projectId +
      '/' +
      Constants.apiEndPoints.getPods +
      '?page=' +
      filters.page +
      '&per_page=' +
      filters.perPage +
      '&designation=' +
      filters.designation;
    if (query) {
      API = `${API}&search=${query}`;
    }
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => res),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public updatePod(podData, id) {
    const API = this.API_BASE_URL + Constants.apiEndPoints.getPods + '/' + id;
    return this.http
      .put(
        API,
        JSON.stringify(podData),
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => res),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public createPod(podData) {
    const API = this.API_BASE_URL + Constants.apiEndPoints.getPods;
    return this.http
      .post(
        API,
        JSON.stringify(podData),
        this.dataService.getRequestOptionArgs('application/json')
      )
      .pipe(
        map((res) => res),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getPodLocations(podId) {
    const API = `${
      this.API_BASE_URL
    }${Constants.apiEndPoints.SquadTribeLocation.replace('[pod_id]', podId)}`;
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }

  getSquadTimelines(podId?) {
    const API = `${
      this.API_BASE_URL
    }${Constants.apiEndPoints.squadTimeline.replace('[pod_id]', podId)}`;
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }

  public getVericalsList() {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.projects +
      '/verticals' +
      this.commonService.forceRedirect();
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res['data']) {
            return res;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getServiceRegions() {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.getAllManager +
      '/service_regions' +
      this.commonService.forceRedirect();
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res['data']) {
            if (this.commonService.notifyServiceRegionData) {
              this.commonService.notifyServiceRegionData.next(true);
            }
            return res;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }
}
