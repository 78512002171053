import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker-ivy';
import moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../../shared/shared-module/services/shared.service';
import { LoaderService } from '../../../services/loader.service';
import { ToastMessageService } from '../../../services/toast.message.service';
import { TaskService } from '../task.service';

@Component({
  selector: 'app-my-task',
  templateUrl: './my-task.component.html',
  styleUrls: ['./my-task.component.css']
})
export class MyTaskComponent implements OnInit {
  taskList = [];
  taskDetails = false;
  page = 1;
  currentTask = {
    assignee: { id: 0, name: '', type: '' },
    attachments: [],
    builder_card_phase: { id: 0, name: '' },
    created_at: '',
    description: '',
    due_date: '',
    id: 0,
    owner: {},
    project: {},
    project_phases: {},
    status: '',
    title: '',
    todo_type: '',
    updated_at: ''
  };
  currentIndex = -1;
  @ViewChild('confirmBlockModal', { static: true })
  confirmBlockModal: ModalDirective;
  assignee;
  assignee_to_send;
  formattedDate;
  due_date;
  showMulti = false;
  removeAttachments = [];
  CommonErrorMsg = "";
  public uploadfileName = "";
  file;
  public restoreAttachments;
  public removeFile = false;
  public startDate = moment()
    .subtract(1, "days")
    .format("DD-MM-YYYY")
    .split("-");
  myDatePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: "mmm dd, yyyy",
    alignSelectorRight: true,
    monthSelector: true,
    yearSelector: true,
    disableUntil: {
      day: parseInt(this.startDate[0]),
      month: parseInt(this.startDate[1]),
      year: parseInt(this.startDate[2])
    }
  };
  fileToBeUpload;
  fileUpload = false;
  builder_card_phase_id = null;
  statusList = [{ key: "", value: "Please select" }];
  total_count = 15;
  scrollLoader = false;
  public projectPhase = [{ key: '', value: 'Select Phase' }];

  constructor(
    private taskService: TaskService,
    private loaderService: LoaderService,
    private router: Router,
    private toastMessageService: ToastMessageService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
  ) {}
  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false,
    toolbarHiddenButtons: [
      ["subscript", "superscript", "fontName"],
      [
        'unlink',
        'textColor',
        'insertImage',
        'backgroundColor',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };
  ngOnInit(): void {
    this.getTask(false);
  }

  dateChanged(date: IMyDateModel) {
    this.formattedDate = moment(date.singleDate.jsDate).format("ll");
  }

  getTask(isAppend: boolean): void {
    if (isAppend) {
      this.page += 1;
    } else {
      this.page = 1;
      this.loaderService.show();
    }
    this.taskService.getMyTasks(this.page).subscribe(
      (data) => {
        if (isAppend) {
          this.scrollLoader = false;
          this.taskList = this.taskList.concat(data.mytodos);
        } else {
          this.taskList = data.mytodos;
        }
        this.total_count = data.total_count;

        this.loaderService.hide();
      },
      (err) => {
        this.loaderService.hide();
        this.scrollLoader = false;
        this.showErrorMessage(err);
      },
    );
  }

  gotoProject(p_id: number, user_present_on_project) {
    if (!user_present_on_project) {
      return false;
    }
    this.router.navigate([`/projects/${p_id}/todos-queries`]).then(() => {
      window.location.reload();
    });
  }
  onAssigneeChange(assignee) {
    this.currentTask.assignee = assignee;
  }
  backToList(): void {
    this.taskDetails = false;
    if (
      this.currentTask.assignee &&
      this.currentTask.assignee.id !== this.assignee_to_send.id
    ) {
      this.currentTask.assignee = this.assignee_to_send;
    }
  }
  openDetailView(index: number) {
    this.showMulti = false;
    this.currentIndex = index;
    this.currentTask = this.taskList[index];
    if (this.currentTask.due_date) {
      this.formattedDate = moment(this.currentTask.due_date).format("ll");
      this.due_date = {
        isRange: false,
        singleDate: { jsDate: new Date(this.currentTask.due_date) }
      };
    } else {
      this.due_date = "";
      this.formattedDate = null;
    }
    if (this.currentTask.assignee) {
      this.assignee = this.currentTask.assignee.id;
      this.assignee_to_send = this.currentTask.assignee;
    } else {
      this.currentTask.assignee = { id: 0, name: '', type: '' };
      this.assignee_to_send = { id: '', name: '', type: '' };
    }
    this.currentTask.builder_card_phase &&
      (this.builder_card_phase_id = this.currentTask.builder_card_phase.id);
    //this.builder_card_phase_id =  this.currentTask.builder_card_phase?this.currentTask.builder_card_phase.id : null
    this.restoreAttachments = this.currentTask.attachments;
    this.removeFile = false;
    this.fileUpload = false;
    if (this.currentTask.status == "delayed") {
      this.statusList = [
        { key: 'delayed', value: 'Overdue' },
        { key: 'completed', value: 'Completed' }
      ];
    } else if (this.currentTask.status == "archive") {
      this.statusList = [
        { key: 'to_review', value: 'To Review' },
        { key: 'archive', value: 'Archived' }
      ];
    } else if (this.currentTask.status == "completed") {
      this.statusList = [
        { key: 'pending', value: 'In Progress' },
        { key: 'completed', value: 'Completed' }
      ];
    } else if (this.currentTask.status == "to_review") {
      this.statusList = [
        { key: 'pending', value: 'In Progress' },
        { key: 'to_review', value: 'To Review' },
        { key: 'archive', value: 'Archived' }
      ];
    } else if (this.currentTask.status == "pending") {
      this.statusList = [
        { key: 'pending', value: 'In Progress' },
        { key: 'archive', value: 'Archived' },
        { key: 'completed', value: 'Completed' }
      ];
    }

    this.taskDetails = true;
  }

  cancelTask(): void {
    this.removeFile = false;
    this.fileUpload = false;
    this.uploadfileName = "";
    this.fileToBeUpload = "";
    this.currentIndex = -1;
    this.currentTask = {
      assignee: { id: 0, name: '', type: '' },
      attachments: [],
      builder_card_phase: { id: 0, name: '' },
      created_at: '',
      description: '',
      due_date: '',
      id: 0,
      owner: {},
      project: {},
      project_phases: {},
      status: '',
      title: '',
      todo_type: '',
      updated_at: ''
    };
    this.taskDetails = false;
  }

  saveTask(): void {
    const todoObj = {
      todo_type: this.currentTask.todo_type,
      description: this.currentTask.description,
      status: this.currentTask.status,
      due_date: this.due_date.singleDate
        ? this.due_date.singleDate.formatted
        : this.currentTask.due_date,
      remove_attachment: this.removeFile,
      attachments: this.removeAttachments,
      builder_card_phase_id: this.builder_card_phase_id,
      fileToBeUpload: this.fileToBeUpload,
      assignee_type: this.currentTask.assignee.type,
      assignee_id: this.currentTask.assignee.id
    };
    this.loaderService.show();
    this.taskService
      .updateTask(todoObj, this.taskList[this.currentIndex].id)
      .subscribe(
        (response) => {
          this.currentTask = response.mytodo;
          this.taskList[this.currentIndex] = response.mytodo;
          this.restoreAttachments = response.mytodo.attachments;
          this.loaderService.hide();
          this.removeFile = false;
          this.fileUpload = false;
          this.uploadfileName = "";
          this.fileToBeUpload = "";
          this.taskDetails = false;
          this.currentIndex = -1;
          this.toastMessageService.showToastMessage(
            "Detail(s) updated successfully.",
          );
        },
        (error) => {
          this.loaderService.hide();
          this.showErrorMessage(error);
        },
      );
  }

  showErrorMessage(error) {
    this.loaderService.hide();
    if (error && error.error) {
      this.CommonErrorMsg = error.error;
    } else if (error && error.message) {
      this.CommonErrorMsg = error.message;
    }
    setTimeout(() => {
      this.CommonErrorMsg = "";
    }, 5000);
  }

  openConfirmModal(index: number): void {
    this.currentIndex = index;
    this.confirmBlockModal.show();
  }

  editorLink(event) {
    this.sharedService.editorLink(event);
  }

  markComplete() {
    this.taskList[this.currentIndex].status = "completed";
    const data = {
      todo_type: this.taskList[this.currentIndex].todo_type,
      description: this.taskList[this.currentIndex].description,
      status: 'completed'
    };
    this.taskService
      .updateTask(data, this.taskList[this.currentIndex].id)
      .subscribe(
        () => {
          this.currentIndex = -1;
        },
        () => {}
      );
  }

  confirmComplete() {
    this.markComplete();
    this.confirmBlockModal.hide();
  }

  closeConfirmModal() {
    this.confirmBlockModal.hide();
    this.currentIndex = -1;
  }

  public deleteFile() {
    this.file = "";
    this.removeAttachments = this.currentTask.attachments;
    this.restoreAttachments = this.currentTask.attachments;
    if (this.currentTask.attachments.length) {
      this.removeFile = true;
    }
    this.currentTask.attachments = [];
    this.uploadfileName = "";
    this.fileToBeUpload = "";
  }

  onFileChange(event) {
    this.fileUpload = true;
    if (event.target.files.length > 0) {
      this.uploadfileName = event.target.files[0].name;
      this.fileToBeUpload = event.target.files[0];
    } else {
      this.uploadfileName = "";
      this.fileToBeUpload = "";
    }
  }

  public handleScroll() {
    if (this.total_count == 15 && !this.scrollLoader) {
      this.getTask(true);
      this.scrollLoader = true;
    }
  }

  public downloadFile() {
    window.open(this.currentTask.attachments[0].url, "_blank");
  }
}
