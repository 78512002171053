<div
  class="mytask-planner"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollUpDistance]="1.5"
  [infiniteScrollThrottle]="10"
  [scrollWindow]="false"
  (scrolled)="handleScroll()"
>
  <div class="mytask-todos-header" *ngIf="taskList.length > 0 && !taskDetails">
    <div class="revamp-right-content">
      <ul class="tasks-queries-list">
        <li *ngFor="let task of taskList; index as i">
          <div class="revamp-todo-list">
            <div class="todo-name">
              <span
                *ngIf="!(task.status === 'to_review')"
                class="custom-radio-box"
                (click)="!(task.status === 'to_review') && openConfirmModal(i)"
                [ngClass]="{ active: task.status === 'completed' }"
              >
                <em class="zmdi zmdi-check"></em>
              </span>
              <span (click)="openDetailView(i)">{{ task.title }}</span>
            </div>
            <div class="todo-more-info">
              <div class="todo-more-item">
                <span>
                  <strong
                    *ngIf="task.status !== 'to_review'; else elseStatusBlock"
                    class="badge-style"
                    [ngClass]="{
                      green: task.status === 'completed',
                      yellow: task.status === 'reopen',
                      red: task.status === 'delayed',
                      pending_style: task.status === 'pending'
                    }"
                    >{{ task.status | titlecase }}</strong
                  >
                  <ng-template #elseStatusBlock
                    ><strong class="badge-style amber">To Review</strong>
                  </ng-template>
                </span>
              </div>
              <div class="todo-more-item">
                <span>
                  <strong
                    (click)="
                      gotoProject(
                        task.project.id,
                        task.project.user_present_on_project
                      )
                    "
                    [attr.title]="
                      task.project.user_present_on_project === false
                        ? 'You are not assigned on this buildcard'
                        : ''
                    "
                    [ngClass]="{
                      disable_mode:
                        task.project.user_present_on_project === false
                    }"
                    class="badge-style project"
                    >{{ task.project?.name }}</strong
                  >
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="pagination-loader" *ngIf="scrollLoader">
    <img
      src="assets/images/pagination-loader.gif"
      alt=""
      width="100"
      height="100"
    />
  </div>

  <div class="mytask-todo-detail revamp-right-content" *ngIf="taskDetails">
    <div class="todosQueries">
      <div class="todos-right">
        <div class="todos-inner">
          <div class="todo-title-wrp flx-center">
            <span
              class="zmdi zmdi-chevron-left back-task"
              (click)="backToList()"
            ></span>
            <h4>{{ currentTask.title }}</h4>
          </div>
          <div class="task-detail">
            <form #todoForm="ngForm">
              <div class="task-detail-row custom-angular-editor">
                <label>Description :</label>
                <custom-angular-editor
                  [placeholder]="'description'"
                  [config]="editorConfig"
                  name="description"
                  [(ngModel)]="currentTask.description"
                  class="activeToolbar"
                  (click)="editorLink($event)"
                >
                </custom-angular-editor>
              </div>
              <div class="task-detail-row d-flex justify-content-between">
                <div
                  class="pro-status d-flex justify-content-start align-items-center"
                >
                  <label>Status</label>
                  <div class="status-option">
                    <fa-icon
                      [ngClass]="{
                        green: currentTask.status === 'completed',
                        yellow:
                          currentTask.status === 'reopen' ||
                          currentTask.status === 'to_review',
                        red: currentTask.status === 'delayed'
                      }"
                      [icon]="['fas', 'circle']"
                    ></fa-icon>
                    <select name="status" [(ngModel)]="currentTask.status">
                      <option
                        *ngFor="let status of statusList"
                        [value]="status.key"
                      >
                        {{ status.value }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="task-detail-row d-flex justify-content-between">
                <div
                  class="pro-status d-flex justify-content-start align-items-center"
                >
                  <label>Phases</label>
                  <div class="status-option">
                    <select
                      [(ngModel)]="builder_card_phase_id"
                      id="phase"
                      name="phase"
                    >
                      <ng-container
                        *ngFor="let phase of currentTask?.project_phases"
                      >
                        <option *ngIf="!phase.custom" [value]="phase.id">
                          {{ phase.name }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
              <div class="task-detail-row d-flex justify-content-between">
                <div
                  class="pro-status d-flex justify-content-start align-items-center"
                >
                  <label>Type</label>
                  <div class="custom-radio">
                    <div class="radio radio-primary">
                      <input
                        [attr.disabled]="
                          currentTask.status === 'completed' ||
                          currentTask.status === 'archive'
                            ? ''
                            : null
                        "
                        type="radio"
                        name="todo_type"
                        id="radio7"
                        value="todo"
                        [(ngModel)]="currentTask.todo_type"
                      />
                      <label for="radio7"> ToDo </label>
                    </div>
                    <div class="radio radio-primary">
                      <input
                        [attr.disabled]="
                          currentTask.status === 'completed' ||
                          currentTask.status === 'archive'
                            ? ''
                            : null
                        "
                        type="radio"
                        name="todo_type"
                        id="radio8"
                        value="query"
                        [(ngModel)]="currentTask.todo_type"
                      />
                      <label for="radio8"> Query </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="task-detail-row d-flex justify-content-between">
                <div
                  class="pro-status d-flex justify-content-start align-items-center"
                >
                  <label>Due date</label>
                  <div class="status-option">
                    <div class="cmn-datepicker-wrp no-border">
                      <input
                        class="input-box show-placeholder"
                        placeholder="Select date"
                        angular-mydatepicker
                        (click)="duedp.toggleCalendar()"
                        [(ngModel)]="due_date"
                        #duedp="angular-mydatepicker"
                        (dateChanged)="dateChanged($event)"
                        name="due_date"
                        [options]="myDatePickerOptions"
                        [disabled]="currentTask.status === 'completed'"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="task-detail-row d-flex justify-content-between">
                <div
                  class="pro-status d-flex justify-content-start align-items-center"
                >
                  <label>Created on</label>
                  <div class="status-option">
                    <span>{{
                      currentTask.created_at | date: "mediumDate"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="task-detail-row d-flex justify-content-between">
                <div
                  class="pro-status d-flex justify-content-start align-items-center"
                >
                  <label>Assigned to</label>
                  <div class="status-option">
                    <div class="assignedByName me-auto">
                      <ng-container
                        *ngIf="
                          currentTask &&
                            currentTask.assignee &&
                            currentTask.assignee.name;
                          else elseBlock
                        "
                      >
                        <span class="initial-circle">{{
                          currentTask?.assignee?.name | commentName | uppercase
                        }}</span>
                        <p class="assignedBy-name">
                          {{ currentTask?.assignee?.name }}
                        </p>
                      </ng-container>
                      <ng-template #elseBlock>
                        <p class="assignedBy-name assignee-name">
                          Select Assignee
                        </p>
                      </ng-template>

                      <div
                        class="global-assigned-box"
                        *ngIf="
                          currentTask.status !== 'completed' &&
                          currentTask.value !== 'archive'
                        "
                      >
                        <div class="custom-multi-wrp">
                          <div
                            class="custom-multi-checkbox select-box open-multisearch"
                            (click)="showMulti = !showMulti"
                          ></div>
                          <div class="multi-search-checkbox" *ngIf="showMulti">
                            <ul class="group-feature-checkbox">
                              <li>
                                <div class="custom-checkbox heading-dropdown">
                                  <label>Client</label>
                                </div>
                              </li>
                              <li
                                class="ml10"
                                *ngFor="
                                  let client of currentTask.project?.clients
                                "
                              >
                                <div class="custom-checkbox">
                                  <label>
                                    <input
                                      type="radio"
                                      [(ngModel)]="assignee"
                                      name="assignee"
                                      [value]="client.id"
                                      (change)="onAssigneeChange(client)"
                                    /><span class="checkmark"></span>
                                    {{ client.name }}
                                  </label>
                                </div>
                              </li>
                            </ul>
                            <ul class="group-feature-checkbox">
                              <li>
                                <div class="custom-checkbox heading-dropdown">
                                  <label>Squad</label>
                                </div>
                              </li>
                              <li
                                class="ml10"
                                *ngFor="let user of currentTask.project?.users"
                              >
                                <div class="custom-checkbox">
                                  <label>
                                    <input
                                      type="radio"
                                      [(ngModel)]="assignee"
                                      name="assignee"
                                      [value]="user.id"
                                      (change)="onAssigneeChange(user)"
                                    /><span class="checkmark"></span>
                                    {{ user.name }}
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="task-detail-row d-flex justify-content-start">
                <label>Attached File</label>
                <div
                  class="attach-file"
                  *ngIf="currentTask?.attachments?.length === 0"
                >
                  <img
                    src="assets/images/attachment-paperclip.svg"
                    *ngIf="uploadfileName === ''"
                    alt="paperclip"
                  />
                  <span *ngIf="uploadfileName === ''">Attach file</span>
                  <input
                    type="file"
                    id="uploadImage"
                    [(ngModel)]="file"
                    name="file"
                    (change)="onFileChange($event)"
                  />
                </div>
                <div
                  class="attach-file-info"
                  *ngIf="
                    currentTask?.attachments?.length === 0 &&
                    uploadfileName !== ''
                  "
                >
                  <span id="doc-name">{{ uploadfileName }}</span>
                  <img
                    src="assets/images/attatch-cross.svg"
                    (click)="deleteFile()"
                    class="right-pos"
                    alt="attach-cross"
                  />
                </div>
                <div
                  class="attach-file-info"
                  *ngIf="currentTask?.attachments?.length > 0"
                >
                  <span id="doc-name">{{
                    currentTask?.attachments[0]?.file_identifier
                  }}</span>
                  <div class="right-pos">
                    <img
                      src="assets/images/app-window-download.svg"
                      (click)="downloadFile()"
                      alt="app-window-download"
                    />
                    <img
                      src="assets/images/attatch-cross.svg"
                      class="space-left-side"
                      (click)="deleteFile()"
                      alt="attach-cross-svg"
                    />
                  </div>
                </div>
              </div>
              <div
                class="task-detail-row d-flex justify-content-between"
                [ngClass]="{ hide: todoForm.pristine && !removeFile }"
              >
                <div class="grouping-icon">
                  <span class="save-task" (click)="saveTask()">
                    <fa-icon [icon]="['fas', 'floppy-disk']"></fa-icon>
                    Save</span
                  >
                  <span class="save-task" (click)="cancelTask()">
                    <fa-icon [icon]="['fas', 'times']"></fa-icon>
                    Cancel</span
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="empty-result" *ngIf="taskList.length === 0">
    <div class="empty-result-inner">
      <img src="assets/images/emptyresult.svg" alt="emptyresult" />
      <div class="heading-empty">Well Done!</div>
      <div class="subheading-empty">All tasks are complete.</div>
    </div>
  </div>
</div>

<div
  bsModal
  #confirmBlockModal="bs-modal"
  [config]="{ backdrop: false }"
  class="client-modal delTask-modal modal fade"
  id="confirmBlockPopup"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content transparent-modal">
      <div class="modal-body">
        <div class="delete-task-block">
          <h3>Confirm Complete Task</h3>
          <p>Do you wish to proceed?</p>
        </div>
        <div class="form-group btn-block d-flex justify-content-end">
          <button
            class="btn cancel-btn"
            type="button"
            (click)="closeConfirmModal()"
          >
            Cancel
          </button>
          <button class="btn" type="button" (click)="confirmComplete()">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
