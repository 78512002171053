import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map
} from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { DataService } from '../../../../shared/shared-module/services/data.service';
import { jsonwebtokenEncrypt } from '../../../../shared/shared-module/utility/utility.functions';
import { NotificationCenterResponseActionType } from '../enum/response-action';
import {
  NotificationCenterState,
  NotificationError,
  NotificationResponse
} from '../interfaces/notification-center';

const initialState: NotificationCenterState = {
  unreadNotifications: [],
  allNotifications: [],
  unseenNotificationsCount: 0,
  unreadNotificationsCount: 0,
  dnd: false,
  isLoading: false,
  webSocketError: null,
  newNotifications: null,
  nextPage: 1,
  unseenNotificationIds: new Set<number>()
};

@Injectable({
  providedIn: 'root'
})
export class NotificationCenterService {
  private _notificationCenterState: BehaviorSubject<NotificationCenterState> =
    new BehaviorSubject(initialState);
  constructor(private dataService: DataService) {}

  get notificationState$() {
    return this._notificationCenterState.asObservable();
  }

  generateJwtToken() {
    const payload = { user_id: this.dataService.user.id };
    return jsonwebtokenEncrypt(payload, environment.NOTIFICATION_TOKEN);
  }

  setWebSocketStateByType(webSocketData: NotificationResponse) {
    switch (webSocketData.type) {
      case NotificationCenterResponseActionType.Notifications_Count_Updated:
        this.setUnreadNotificationsCount(webSocketData.unreadNotificationCount);
        this.setUnseenNotificationsCount(
          webSocketData.unseenNotificationsCount
        );
        break;
      case NotificationCenterResponseActionType.Mark_All_Read:
        this.setIsLoading(true);
        this.setNextPage(1);
        this.setAllNotifications([]);
        this.setUnreadNotifications([]);
        break;
      case NotificationCenterResponseActionType.All_Notifications:
        this.setIsLoading(false);
        if (webSocketData.pagination.currentPage === 1) {
          this.setAllNotifications(webSocketData.data);
        } else {
          const notifications = [
            ...this.getNotificationState()?.allNotifications
          ];
          for (const receivedNotification of webSocketData.data) {
            if (
              notifications[notifications.length - 1].key ==
              receivedNotification.key
            ) {
              notifications[notifications.length - 1].value = notifications[
                notifications.length - 1
              ].value.concat(receivedNotification.value);
            } else {
              notifications.push(receivedNotification);
            }
          }
          this.setAllNotifications(notifications);
        }
        if (
          webSocketData.pagination.currentPage <
          webSocketData.pagination.totalPages
        ) {
          this.setNextPage(webSocketData.pagination.currentPage + 1);
        } else {
          this.setNextPage(null);
        }
        this.setUnreadNotificationsCount(
          webSocketData?.unreadNotificationCount
        );
        break;
      case NotificationCenterResponseActionType.Unread_Notifications:
        this.setIsLoading(false);
        if (webSocketData.pagination.currentPage === 1) {
          this.setUnreadNotifications(webSocketData.data);
        } else {
          const notifications = [
            ...this.getNotificationState()?.unreadNotifications
          ];
          for (const receivedNotification of webSocketData.data) {
            if (
              notifications[notifications.length - 1].key ==
              receivedNotification.key
            ) {
              notifications[notifications.length - 1].value = notifications[
                notifications.length - 1
              ].value.concat(receivedNotification.value);
            } else {
              notifications.push(receivedNotification);
            }
          }
          this.setUnreadNotifications(notifications);
        }
        if (
          webSocketData.pagination.currentPage <
          webSocketData.pagination.totalPages
        ) {
          this.setNextPage(webSocketData.pagination.currentPage + 1);
        } else {
          this.setNextPage(null);
        }
        break;
      case NotificationCenterResponseActionType.Unseen_Notifications_Count:
        this.setUnseenNotificationsCount(
          webSocketData.unseenNotificationsCount
        );

        break;
      case NotificationCenterResponseActionType.Unread_Notification_Count:
        this.setUnreadNotificationsCount(webSocketData.unreadNotificationCount);
        break;
      case NotificationCenterResponseActionType.Subscription_Confirmation:
        this.setDnd(webSocketData.dnd);
        this.setUnreadNotificationsCount(webSocketData.unreadNotificationCount);
        this.setUnseenNotificationsCount(
          webSocketData.unseenNotificationsCount
        );
        break;
      case NotificationCenterResponseActionType.Dnd_Updated:
        this.setDnd(webSocketData.dnd);
        break;
      case NotificationCenterResponseActionType.LatestBroadcastNotification:
        const allnotifications = [  // eslint-disable-line
          ...this.getNotificationState()?.allNotifications
        ];
        const unreadnotifications = [   // eslint-disable-line
          ...this.getNotificationState()?.unreadNotifications
        ];
        // Set All Notifications
        for (const receivedNewNotification of webSocketData.data) {
          if (allnotifications.length == 0) {
            this.setAllNotifications([...webSocketData.data]);
            continue;
          }
          if (
            allnotifications.length &&
            allnotifications[0].key == receivedNewNotification.key
          ) {
            allnotifications[0].value = [
              ...receivedNewNotification.value
            ].concat(allnotifications[0].value);
            this.setAllNotifications([...allnotifications]);
          } else {
            this.setAllNotifications([
              ...webSocketData.data,
              ...allnotifications
            ]);
          }
        }

        // Set Unread Notifications
        for (const receivedNewNotification of webSocketData.data) {
          if (unreadnotifications.length == 0) {
            this.setUnreadNotifications([...webSocketData.data]);
            continue;
          }
          if (
            unreadnotifications.length &&
            unreadnotifications[0].key == receivedNewNotification.key
          ) {
            unreadnotifications[0].value = [
              ...receivedNewNotification.value
            ].concat(unreadnotifications[0].value);
            this.setUnreadNotifications([...unreadnotifications]);
          } else {
            this.setUnreadNotifications([
              ...webSocketData.data,
              ...unreadnotifications
            ]);
          }
        }
        this.setNotificationPopupData(webSocketData.data);

        let newNotificationCount = 0;  // eslint-disable-line
        for (const event of webSocketData?.data) {
          newNotificationCount =
            newNotificationCount + (event?.value?.length || 0);
        }

        this.setUnseenNotificationsCount(
          this._notificationCenterState.value.unseenNotificationsCount +
            newNotificationCount
        );
        this.setUnreadNotificationsCount(
          this._notificationCenterState.value.unreadNotificationsCount +
            newNotificationCount
        );

        break;
      case NotificationCenterResponseActionType.Error:
        this.setWebSocketError({
          code: webSocketData.code,
          message: webSocketData.message
        });
    }
  }

  setAllNotifications(data: any) {
    this._notificationCenterState.next({
      ...this.getNotificationState(),
      allNotifications: data
    });
    for (const receivedNotification of data) {
      this.setUnseenNotificationIds(
        receivedNotification.value.filter((x) => !x.isSeen).map((x) => x.id)
      );
    }
  }

  setUnreadNotifications(data: any) {
    this._notificationCenterState.next({
      ...this.getNotificationState(),
      unreadNotifications: data
    });
    for (const receivedNotification of data) {
      this.setUnseenNotificationIds(
        receivedNotification.value.filter((x) => !x.isSeen).map((x) => x.id)
      );
    }
  }

  setDnd(data: boolean) {
    this._notificationCenterState.next({
      ...this.getNotificationState(),
      dnd: data
    });
  }

  setUnseenNotificationsCount(data: number) {
    if (data === 0) {
      this._notificationCenterState.next({
        ...this.getNotificationState(),
        unseenNotificationsCount: data,
        unseenNotificationIds: new Set()
      });
    } else {
      this._notificationCenterState.next({
        ...this.getNotificationState(),
        unseenNotificationsCount: data
      });
    }
  }

  setUnreadNotificationsCount(data: number) {
    this._notificationCenterState.next({
      ...this.getNotificationState(),
      unreadNotificationsCount: data
    });
  }

  setIsLoading(data: boolean) {
    this._notificationCenterState.next({
      ...this.getNotificationState(),
      isLoading: data
    });
  }

  setWebSocketError(data: NotificationError) {
    this._notificationCenterState.next({
      ...this.getNotificationState(),
      webSocketError: data
    });
  }

  setNotificationPopupData(data: any) {
    this._notificationCenterState.next({
      ...this.getNotificationState(),
      newNotifications: data
    });
  }

  setNextPage(data: number) {
    this._notificationCenterState.next({
      ...this.getNotificationState(),
      nextPage: data
    });
  }

  setUnseenNotificationIds(ids: number[]) {
    this._notificationCenterState.next({
      ...this.getNotificationState(),
      unseenNotificationIds: ids.reduce((acc, v) => {
        if (!acc.has(v)) {
          acc.add(v);
        }
        return acc;
      }, this.getNotificationState().unseenNotificationIds)
    });
  }

  removeUnseenNotificationIds(ids: number[]) {
    this._notificationCenterState.next({
      ...this.getNotificationState(),
      unseenNotificationIds: ids.reduce((acc, v) => {
        if (acc.has(v)) {
          acc.delete(v);
        }
        return acc;
      }, this.getNotificationState().unseenNotificationIds)
    });
  }

  getAllNotifications() {
    return this.notificationState$.pipe(
      map((x) => x.allNotifications),
      distinctUntilChanged()
    );
  }

  getUnreadNotifications() {
    return this.notificationState$.pipe(
      map((x) => x.unreadNotifications),
      distinctUntilChanged()
    );
  }

  getUnreadNotificationsCount() {
    return this.notificationState$.pipe(
      map((x) => x.unreadNotificationsCount),
      distinctUntilChanged()
    );
  }

  getUnseenNotificationsCount() {
    return this.notificationState$.pipe(
      map((x) => x.unseenNotificationsCount),
      distinctUntilChanged()
    );
  }

  getPage() {
    return this.notificationState$.pipe(
      map((x) => x.nextPage),
      distinctUntilChanged()
    );
  }

  getIsLoading() {
    return this.notificationState$.pipe(
      map((x) => x.isLoading),
      distinctUntilChanged()
    );
  }

  getNotificationPopupData() {
    return this.notificationState$.pipe(
      filter((x) => !!x.newNotifications),
      map((x) => x.newNotifications),
      distinctUntilChanged()
    );
  }

  getNotificationState() {
    return this._notificationCenterState.getValue();
  }
}
