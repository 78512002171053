import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PodService } from '../../services/pod.service';
import {
  CreatePod,
  GetPod,
  GetPodLocations,
  GetPods,
  GetPodTimeline,
  GetServiceRegions,
  GetVericalsList,
  SetFilterViewState,
  UpdatePod
} from '../pod/pod.action';
import { SetToasterErrorMsg } from '../toaster/toaster.action';
import { PodModel } from './pod.model';

@State<PodModel>({
  name: 'podstate',
  defaults: {
    data: {},
    loading: false,
    title: 'pod',
    storeProjects: {},
    podList: [],
    pod: {},
    podForTribe: [],
    locations: [],
    filterViewState: {},
    podTimeline: {},
    verticalList: [],
    serviceRegionList: [],
    podNextPage: 1,
    loadingPods: false,
    podCount: 0
  }
})
@Injectable()
export class PodState {
  podForTribe = [];
  constructor(
    private podService: PodService,
    private store: Store
  ) {}

  @Action(GetPods)
  getDataFromState(
    ctx: StateContext<PodModel>,
    { isScroll, search, status, location, members, featureScore }
  ) {
    const state = ctx.getState();
    if (state.loadingPods || (isScroll && !state.podNextPage)) {
      return;
    }
    ctx.patchState({
      loading: true,
      loadingPods: true,
      podNextPage:
        (search !== null && search !== undefined && search.trim()) || !isScroll
          ? 1
          : state.podNextPage,
      podList:
        (search !== null && search !== undefined && search.trim()) || !isScroll
          ? []
          : state.podList
    });
    return this.podService
      .getPods(
        ctx.getState().podNextPage,
        15,
        search,
        status,
        location,
        members,
        featureScore
      )
      .pipe(
        tap((returnData) => {
          ctx.patchState({
            loading: false,
            loadingPods: false,
            data:
              returnData && returnData['message'] === 'success'
                ? returnData['data']
                : null,
            podList: this.assignPodData(
              ctx.getState().podNextPage,
              ctx.getState().podList,
              returnData['data']?.pods
            ),
            podCount: returnData['data']?.total_count,
            podNextPage: returnData['data']?.next_page,
            storeProjects:
              returnData && returnData['message'] === 'success'
                ? returnData['data']?.pods?.find((pod) => {
                    if (pod.is_store_pod === true) {
                      return pod;
                    }
                  })
                : [],
            podForTribe: this.podForTribe
          });
        }),
        catchError((error) => {
          ctx.patchState({
            loading: false,
            loadingPods: false
          });
          return throwError(() => error);
        })
      );
  }

  @Action(UpdatePod)
  updatePod(ctx: StateContext<PodModel>, { data, id}) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: false });
    return this.podService.updatePod(data, id).pipe(
      tap((returnData) => {
        const updatedPodList = [...state.podList];
        if (returnData['message'] === 'success') {
          const index = updatedPodList.findIndex((x) => x.id == id);
          if (index > -1) {
            updatedPodList[index] = returnData['data'].pod;
          }
        }

        ctx.patchState({
          ...state,
          podList: updatedPodList,
          pod: {},
          loading: false
        });
      }),
      catchError((error) => {
        ctx.patchState({
          ...state,
          loading: false
        });
        return throwError(() => error);
      })
    );
  }

  @Action(CreatePod)
  createPod(ctx: StateContext<PodModel>, { data, page }) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: false });
    console.log(page)
    return this.podService.createPod(data).pipe(
      tap((returnData) => {
        const updatedPodList = [...state.podList];
        if (returnData['message'] === 'success' && state?.podList?.length > 0) {
          updatedPodList.unshift(returnData['data']?.pod);
        }
        ctx.patchState({
          ...state,
          podList: updatedPodList,
          pod: {},
          loading: false
        });
      }),
      catchError((error) => {
        ctx.patchState({
          ...state,
          loading: false
        });
        return throwError(() => error);
      })
    );
  }

  @Action(GetPodLocations)
  getPodLocations(ctx: StateContext<any>, { podId }) {
    const state = ctx.getState();
    return (
      this,
      this.podService.getPodLocations(podId).pipe(
        tap((returnData) => {
          const locations =
            returnData && returnData['message'] === 'success'
              ? returnData['data']
              : [];
          ctx.patchState({
            ...state,
            locations: { locations: locations, message: returnData['message'] }
          });
        }),
        catchError((error) => {
          ctx.patchState({
            ...state
          });
          return throwError(() => error);
        })
      )
    );
  }

  @Action(GetPod)
  getPodDataFromState(ctx: StateContext<PodModel>, { id }) {
    // const state = ctx.getState();
    // ctx.patchState({
    //   loading: true
    // });
    return this.podService.getPod(id).pipe(
      tap((returnData) => {
        ctx.patchState({
          pod:
            returnData && returnData['message'] === 'success'
              ? returnData['data']
              : null
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: false
        });
        this.store.dispatch([new SetToasterErrorMsg('Something went wrong.')]);
        return throwError(() => error);
      })
    );
  }

  @Action(SetFilterViewState)
  SetFiltersSearch(
    { getState, setState }: StateContext<any>,
    { filters, searchText, viewType }: SetFilterViewState
  ) {
    setState({
      ...getState(),
      filterViewState: {
        filters: filters,
        searchText: searchText,
        viewType: viewType
      }
    });
  }

  @Action(GetPodTimeline)
  getSquadTimelines(ctx: StateContext<PodModel>, { id }) {
    // const state = ctx.getState();
    // ctx.patchState({
    //   loading: true
    // });
    return this.podService.getSquadTimelines(id).pipe(
      tap((returnData) => {
        ctx.patchState({
          podTimeline:
            returnData && returnData['message'] === 'success'
              ? returnData['data']
              : null
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: false
        });
        this.store.dispatch([new SetToasterErrorMsg('Something went wrong.')]);
        return throwError(() => error);
      })
    );
  }

  @Action(GetVericalsList)
  getVerticals(ctx: StateContext<PodModel>) {
    // const state = ctx.getState();
    // ctx.patchState({
    //   loading: true
    // });
    return this.podService.getVericalsList().pipe(
      tap((returnData) => {
        ctx.patchState({
          verticalList:
            returnData && returnData['message'] === 'success'
              ? returnData['data']
              : null
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: false
        });
        this.store.dispatch([new SetToasterErrorMsg('Something went wrong.')]);
        return throwError(() => error);
      })
    );
  }

  @Action(GetServiceRegions)
  getServiceRegions(ctx: StateContext<PodModel>) {
    // const state = ctx.getState();
    // ctx.patchState({
    //   loading: true
    // });
    return this.podService.getServiceRegions().pipe(
      tap((returnData) => {
        ctx.patchState({
          serviceRegionList:
            returnData && returnData['message'] === 'success'
              ? returnData['data']
              : null
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: false
        });
        this.store.dispatch([new SetToasterErrorMsg('Something went wrong.')]);
        return throwError(() => error);
      })
    );
  }

  private assignPodData(page, currentPodList, podResponseList) {
    if (page == 1) {
      return podResponseList;
    } else {
      return currentPodList.concat(podResponseList);
    }
  }
}
