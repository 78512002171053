export enum PodActionTypes {
  ADD_ONE = '[Pods] Add One',
  UPDATE_ONE = '[Pods] Update One',
  DELETE_ONE = '[Pods] Delete One',
  GET_ALL = '[Pods] Get All',
  Set_Filter_View_State = '[Pods] Set Filter View State',
  GET_VERTICAL_LIST = '[Pods] Get Vertical List',
  GET_SERVICE_REGION = '[Pods] Get Service Regions'
}

//Read
export class GetPods {
  static readonly type = '[Pods] Fetch';
  constructor(
    public isScroll?: boolean,
    public search?: string,
    public status?: boolean[],
    public location?,
    public members?,
    public featureScore?
  ) {}
}

export class GetPod {
  static readonly type = '[Pod] Fetch Pod';
  constructor(public id: number) {}
}

export class UpdatePod {
  static readonly type = '[Pod] Update';
  constructor(
    public data,
    public id: number,
  ) {}
}

export class CreatePod {
  static readonly type = '[Pod] Create';
  constructor(
    public data,
    public page: number
  ) {}
}

export class GetPodLocations {
  static readonly type = '[Pod] Fetch Pod Locations';
  constructor(public podId: number) {}
}

export class SetFilterViewState {
  static readonly type = PodActionTypes.Set_Filter_View_State;
  constructor(
    public filters,
    public searchText: string,
    public viewType: 'grid' | 'list'
  ) {}
}
export class GetPodTimeline {
  static readonly type = '[Pod] Fetch Pod Timeline';
  constructor(public podId: number) {}
}

export class GetVericalsList {
  static readonly type = PodActionTypes.GET_VERTICAL_LIST;
}
export class GetServiceRegions {
  static readonly type = PodActionTypes.GET_SERVICE_REGION;
}
