import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounce } from '../../../../shared/shared-module/utility/utility.functions';

@Component({
  selector: 'meeting-custom-dropdown-list',
  templateUrl: './meeting-custom-dropdown-list.component.html',
  styleUrls: ['./meeting-custom-dropdown-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MeetingCustomDropdownListComponent)
    }
  ]
})
export class MeetingCustomDropdownListComponent
  implements ControlValueAccessor
{
  isDropdownVisible = false;
  selectedOption;
  isDisabled = false;
  touched = false;
  currentOptionIndex = 0;
  @Input() disabled = false;
  @Input() customClass = '';
  @Input() idPrefix = '';
  @Input() closeOnSelect = true;
  @Input() options = [];
  @Input() optionKeyStr: string;
  @Input() optionValueStr: string;
  @Output() onSelect = new EventEmitter<any>();
  @ViewChild('dropdownWrapper') dropdownWrapper: ElementRef;
  @ViewChild('searchBox', { static: false }) searchBox: ElementRef;
  searchOption = '';

  toggleDropdown() {
    if (!this.isDisabled) {
      this.searchOption = '';
      this.isDropdownVisible = !this.isDropdownVisible;

      if (this.isDropdownVisible) {
        const index = this.options.findIndex(
          (x) =>
            (this.optionKeyStr ? x[this.optionKeyStr] : x) ===
            (this.optionKeyStr
              ? this.selectedOption[this.optionKeyStr]
              : this.selectedOption)
        );
        if (index == -1 || (index > -1 && this.options[index]?.disabled)) {
          return;
        } else {
          this.currentOptionIndex = index;
          this.focusCurrentOption(true);
        }
      }
    }
  }

  onSelectOption(option) {
    if (this.closeOnSelect) {
      this.isDropdownVisible = false;
    }
    this.selectedOption = option;
    this.onChange(
      this.optionKeyStr && option[this.optionKeyStr]
        ? option[this.optionKeyStr]
        : option
    );
    this.onSelect.emit(option);
  }

  handleKeyPress(event) {
    if (!this.isDropdownVisible && event.key === 'Enter') {
      event.preventDefault();
      this.toggleDropdown();
    } else if (this.isDropdownVisible) {
      event.preventDefault();
      const { key } = event;
      switch (key) {
        case 'Tab':
        case 'Escape':
          this.isDropdownVisible = !this.isDropdownVisible;
          break;
        case 'ArrowDown':
          this.moveFocusDown();
          break;
        case 'ArrowUp':
          this.moveFocusUp();
          break;
        case 'Enter':
        case ' ':
          this.selectCurrentOption();
          break;
      }
    }
  }

  searchBoxKeyPress(event: KeyboardEvent) {
    event.stopPropagation();
    if (this.isDropdownVisible) {
      console.log('event - ', event);
      switch (event.key) {
        case 'Tab':
        case 'Escape':
          this.toggleDropdown();
          break;
        case 'ArrowDown':
        case 'Enter':
          if (this.searchOption?.trim()) {
            const filterList = this.filteredList;
            if (filterList.length > 0) {
              const index = this.options.findIndex((option) =>
                this.optionKeyStr
                  ? option[this.optionKeyStr] ===
                    filterList[0][this.optionKeyStr]
                  : option === filterList[0]
              );
              if (index > -1) {
                this.currentOptionIndex = index;
                this.focusCurrentOption();
              }
            }
          } else {
            this.focusCurrentOption();
          }
          if (event.key === 'Enter') {
            this.toggleDropdown();
          }
          break;
      }
    }
  }

  selectCurrentOption() {
    if (this.options[this.currentOptionIndex]?.disabled) {
      return;
    } else {
      this.focusCurrentOption();
    }
    const selectedOption = this.options[this.currentOptionIndex];
    this.onSelectOption(selectedOption);
  }

  moveFocusDown() {
    if (this.currentOptionIndex < this.options.length - 1) {
      let current = this.currentOptionIndex + 1;
      while (
        this.options[current]?.disabled &&
        current < this.options.length - 1
      ) {
        current++;
      }
      if (this.options[current]?.disabled) {
        return;
      } else {
        this.currentOptionIndex = current;
        this.focusCurrentOption();
      }
    } else {
      if (this.options[this.options.length - 1]?.disabled) {
        return;
      } else {
        this.currentOptionIndex = this.options.length - 1;
        this.focusCurrentOption();
      }
    }
  }

  focusCurrentOption = debounce((focusSearchBox = false) => {
    const currentOption = this.options[this.currentOptionIndex];
    const elementId = `#${this.idPrefix}_${this.optionKeyStr && currentOption[this.optionKeyStr]?.toString() ? currentOption[this.optionKeyStr]?.toString().replace(':', '') : currentOption?.toString().replace(':', '')}`;
    const element = this.dropdownWrapper.nativeElement.querySelector(
      elementId
    ) as HTMLElement;
    if (element) {
      element.focus();
      // Scroll the current option into view
      element.scrollIntoView({
        block: 'nearest'
      });
    }
    if (focusSearchBox) {
      this.searchBox?.nativeElement?.focus();
    }
  }, 0);

  moveFocusUp() {
    if (this.currentOptionIndex > 0) {
      let current = this.currentOptionIndex - 1;
      while (this.options[current]?.disabled && current > 0) {
        current--;
      }
      if (this.options[current]?.disabled) {
        return;
      } else {
        this.currentOptionIndex = current;
        this.focusCurrentOption();
      }
    } else {
      if (this.options[0]?.disabled) {
        return;
      } else {
        this.currentOptionIndex = 0;
        this.focusCurrentOption();
      }
    }
  }

  ////////////////////// ControlvalueAccessor Implementation
  writeValue(selectedOption: any) {
    const index = this.options.findIndex(
      (x) => (this.optionKeyStr ? x[this.optionKeyStr] : x) === selectedOption
    );
    if (index == -1 || (index > -1 && this.options[index]?.disabled)) {
      return;
    } else {
      this.currentOptionIndex = index;
      if (this.optionKeyStr) {
        this.selectedOption = this.options[index];
      } else if (!this.optionKeyStr) {
        this.selectedOption = selectedOption;
      }
    }
  }

  registerOnChange(onChange) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  onTouched = () => {};

  onChange = (v: Array<any>) => {
    console.log('v = ', v);
  };

  setDisabledState(disabled: boolean) {
    this.isDisabled = disabled;
  }

  get filteredList() {
    if (!this.searchOption?.trim()) return this.options;
    return this.options.filter((option) =>
      this.optionKeyStr
        ? option[this.optionKeyStr]
            ?.toString()
            .toLowerCase()
            .includes(this.searchOption.toLowerCase())
        : option
            ?.toString()
            .toLowerCase()
            .includes(this.searchOption.toLowerCase())
    );
  }
}
